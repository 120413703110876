export enum SciwormBiolinkModules {
  WORKFLOW = "modules/workflowbiolink",
  IMS_SAMPLE_SEARCH = "modules/ims/sample-search",
  CELLS = "modules/cells",
  CELL_SOURCE = "modules/cell-source",
  CRYOPRESERVATION = "modules/cryopreservation",
  CRYOPRESERVE_AS_CELL_BATCH = "modules/cryopreserve-as-cell-batch",
  ADMIN = "modules/admin2",
  DATA = "modules/data",
  THAW = "modules/thaw",
  PASSAGE_2D = "modules/2D-passage",
  PASSAGE_3D = "modules/3D-passage",
  ASSAY_STAGE_DAY = "modules/assay-plan",
  ASSAY_OVERVIEW = "modules/assay-overview",
  AUTOMATION_ORDER = "modules/automation-order",
  BIOREACTOR = "modules/bioreactor-large-scale",
  BIOREACTOR_FEEDING = "modules/bioreactor-feeding",
  BIOREACTOR_FILL_INOCULATE = "modules/bioreactor-fill-inoculate",
  BIOREACTOR_MEDIUM_CHANGE = "modules/bioreactor-medium-change",
  BIOREACTOR_SAMPLING = "modules/bioreactor-sampling",
  BIOREACTOR_HARVEST = "modules/bioreactor-harvest",
  LABDROID = "modules/labdroid",
  MOSAIC_LOB = "modules/mosaic-lob",
  POOL = "modules/pool",
  STORAGE_REQUEST = "modules/storage-request",
  VIRSCIDIAN = "modules/virscidian",
  PLATE_VIEWER = "modules/plate-viewer",
  REQUEST = "modules/requests/new",
}

function isSciwormBiolink(location: Location) {
  return Object.values(SciwormBiolinkModules).some(
    (module) => location.hash === `#/${module}` || location.hash.startsWith(`#/${module}/`),
  );
}

export function sciwormBiolinkLocation(location: Location) {
  return isSciwormBiolink(location);
}

export function notSciwormBiolinkLocation(location: Location) {
  return !isSciwormBiolink(location);
}
